import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {VerifyValidationService} from '../../services/verify-validation.service';

@Component({
  selector: 'ngx-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
})
export class ValidationComponent implements OnInit {

  title: string = 'VALIDAÇÃO DO CADASTRO';
  token: string;

  showSpinner: boolean = false;
  validationEmailSuccess: boolean = false;
  validationEmailError: boolean = false;

  constructor(private verifyValidationService: VerifyValidationService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params['token'];

    this.validEmail();
  }

  async validEmail() {
    this.showSpinner = true;

    const validation = await this.verifyValidationService.validatingEmail(this.token);

    if (validation === true) {
      this.showSpinner = false;
      this.validationEmailSuccess = true;
      return;
    }
    this.showSpinner = false;
    this.validationEmailError = true;
  }
}
