import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {StorageService} from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private storage: StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token: string = this.storage.getToken();

    // if (request.method === 'POST') {
    //   if (request.url.includes('api.rd.services')) {
    //     token = this.storage.getTokenRdStation();
    //   }
    // }

    const requestCopy = request.clone({
      headers: request.headers
        .set('Content-Type', 'application/json')
        .set('Authorization', `Bearer ${token}`),
    });

    return next.handle(requestCopy);
  }
}
