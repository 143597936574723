import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'ngx-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    /* eslint-disable */
    $('.carousel').carousel({
      interval: 6000
    });
  }

  loadImageError(event: any) {
    const image = event.target.alt;

    if (image === 'First slide') {
      event.target.src = '../../assets/images/login-page/carousel/IMG_1.png';
      event.onerror = null;
    } else if (image === 'Second slide') {
      event.target.src = '../../assets/images/login-page/carousel/IMG_2.png';
      event.onerror = null;
    } else if (image === 'Third slide') {
      event.target.src = '../../assets/images/login-page/carousel/IMG_3.png';
      event.onerror = null;
    }
  }
}
