import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { User } from '../../models/user/User';
import {UserCreate} from '../../models/user/UserCreate';

@Injectable({
  providedIn: 'root',
})
export class UserServerService {

  private URL_SERVER: string = environment.URL_BACKEND_SERVER;

  constructor(private httpClient: HttpClient) { }

  post(resource: string,
       userCreate: UserCreate) {
    return this.httpClient.post<User>(this.URL_SERVER + resource, userCreate);
  }

  put(resource: string,
      userChange: User) {
    return this.httpClient.put<User>(this.URL_SERVER + resource, userChange);
  }
}
