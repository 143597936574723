import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ValidationComponent} from './validation.component';
import {NbLayoutModule} from '@nebular/theme';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
  declarations: [
    ValidationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NbLayoutModule,
    SharedModule,
  ],
})
export class ValidationModule {
}
