import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Login } from '../models/login/Login';
import { LoginServerService } from './server/login-server.service';
import { StorageService } from './storage.service';
import { ReturnErrorLogin } from '../models/login/ReturnErrorLogin';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private router: Router,
              private loginServer: LoginServerService,
              private storage: StorageService) { }

  async verifyLogin(login: Login) {
    const verifyLoginServer = this.loginServer.post('/login', login).toPromise();
    const returnErrorLogin = new ReturnErrorLogin();

    await verifyLoginServer.then((loginResponse) => {
      if (loginResponse.user.isConfirmed) {
        this.storage.save(loginResponse);
        this.router.navigate(['/pages/rooms-conference']);
        returnErrorLogin.isValid = true;
      }
      returnErrorLogin.message = 'Você não validou o email';
    }).catch(() => {
      return returnErrorLogin;
    });
    return returnErrorLogin;
  }
}
