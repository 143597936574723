<nb-layout>
  <nb-layout-column class="col-5 leftside">
    <ngx-carousel></ngx-carousel>
  </nb-layout-column>

  <nb-layout-column class="col-md-7 rightside">
    <div class="logo-vip">
      <a href="https://www.vipsolutions.com.br" target="_blank">
        <img src="../../../assets/images/login-page/logo.svg" width="150" height="150" alt="Logo VIP"/>
      </a>
    </div>

    <div class="center">
      <h1>{{ title }}</h1>
      <form>
        <div class="txt_field text-justify">
          <p>Validando:</p>
        </div>

        <div class="text-center mb-3">
          <div [hidden]="!validationEmailSuccess">
            <h3 class="text-success">Email validado com sucesso!</h3>
            <a class="btn btn-lg button-validation-success" routerLink="/login-register/login">
              Ir para o LOGIN
            </a>
          </div>

          <div class="mb-5" [hidden]="!validationEmailError">
            <h3 class="text-danger">Não foi possível validar o seu email!</h3>
            <p>Entre em contato conosco no nosso
              <a class="text-site-vip" href="https://www.vipsolutions.com.br" target="_blank">site</a>
            </p>
          </div>

          <div class="spinner-border text-danger mb-4" role="status" [hidden]="!showSpinner">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </form>
    </div>
  </nb-layout-column>
</nb-layout>
