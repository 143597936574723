import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthService} from '../services/auth.service';
import {StorageService} from '../services/storage.service';
import {SecurityServerService} from '../services/server/security-server.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService,
              private router: Router,
              private storage: StorageService,
              private securityService: SecurityServerService) {
  }

  // Inicia a verificação primeiro
  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {

    if (this.storage.existsToken()) {
      return this.testServer();
    }
    this.router.navigate(['/login-register/login']);
    return false;
  }

  async testServer() {
    let testReturn = false;
    const retorno = this.securityService.checkToken().toPromise();

    await retorno.then(() => {
      testReturn = true;
    });
    return testReturn;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {

    return true;
  }
}
