import {ReturnResetPassword} from './ReturnResetPassword';

export class ReturnRecover {
  validToken: boolean;
  user: ReturnResetPassword;

  constructor(validToken = false) {
    this.validToken = validToken;
  }
}
