import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService, NbToastrService,
} from '@nebular/theme';
import {Router} from '@angular/router';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {Subject} from 'rxjs';
import {StorageService} from '../../../services/storage.service';
import {EditUserComponent} from '../../../pages/edit-user/edit-user.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  username = this.storage.getUser().name;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  // userMenu = [ { title: 'Editar' }, { icon: 'nb-power', title: 'Sair' } ];
  editProfile = [
    {
      title: 'Editar Usuário',
      icon: 'edit',
      // url: '/pages/edit-user',
    },
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private storage: StorageService,
              private router: Router,
              private dialogService: NbDialogService,
              private toastrService: NbToastrService) {
  }

  openWithoutBackdropClick() {
    this.open(false);
  }

  protected open(closeOnBackdropClick: boolean) {
    this.dialogService.open(EditUserComponent, { closeOnBackdropClick }).onClose
      .subscribe(isEdited => {
        if (isEdited) {
          this.toastrService.success(status, `Usuário alterado com sucesso`);
        }
      });
  }

  ngOnInit() {
    // this.currentTheme = this.themeService.currentTheme;
    //
    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   // .subscribe((users: any) => this.user = users.nick);
    //   .subscribe((users: any) => this.user = users.bruno);
    //
    // const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
    //
    // this.themeService.onThemeChange()
    //   .pipe(
    //     map(({ name }) => name),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  // dropdownMenu() {
  //   return this.menuService.onItemClick()
  //     .pipe(
  //       filter(({ tag }) => tag === 'edit-profile'),
  //       map(({ item: { title } }) => title));
  // }

  logout() {
    this.storage.delete();
    this.router.navigate(['/login-register/login']);
  }
}
