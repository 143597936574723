import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckValidationServerService {

  private URL_SERVER: string = environment.URL_BACKEND_SERVER;

  constructor(private httpClient: HttpClient) { }

  get(resource: string,
      token: string) {
    return this.httpClient.get(`${this.URL_SERVER}${resource}${token}`);
  }
}
