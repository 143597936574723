import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
        Copyright © 1993-2022 | <b><a href="https://www.vipsolutions.com.br" target="_blank">Vip Solutions</a></b>
    </span>
    <div class="socials">
      <a href="https://www.facebook.com/VipSolutionsbr" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://www.instagram.com/vipsolutionsbr/" target="_blank" class="ion ion-social-instagram"></a>
      <a href="https://twitter.com/VipSolutions4" target="_blank" class="ion ion-social-twitter"></a>
      <a href="https://www.youtube.com/c/vipsolutions" target="_blank" class="ion ion-social-youtube"></a>
    </div>
  `,
})
export class FooterComponent {
}
