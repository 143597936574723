<nb-layout>
  <nb-layout-column class="col-6 leftside">
    <ngx-carousel></ngx-carousel>
  </nb-layout-column>


  <nb-layout-column class="col-md-6 rightside">
    <div class="go-back">
      <button routerLink="/login-register/login"><span><img src="../../../../../assets/images/home/arrow-left.svg"
            alt="Flecha Voltar"></span>Voltar</button>
    </div>

    <div class="logo-vip ">
      <a href="https://www.vipsolutions.com.br" target="_blank">
        <img src="../../../assets/images/login-page/logo.png" width="200" height="150" alt="Logo VIP" />
      </a>
    </div>

    <div class="center">
      <h1>{{ title }}</h1>

      <div class="text-center mt-5">
        <div class="spinner-border text-danger mb-4" role="status" [hidden]="!showSpinner">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="text-center mt-5 mb-5" [hidden]="!errorToken">
        <h3 class="text-danger">Acesso inválido!</h3>
        <p class="text-danger mt-1">Verifique o e-mail de redefinição de senha.</p>
      </div>

      <form [formGroup]="formNewPassword" (ngSubmit)="confirm()" [hidden]="!showForm">
        <div class="txt_field">
          <input id="password" type="password" required formControlName="password">
          <label for="password">Senha</label>

          <ng-container *ngIf="formNewPassword.controls.password.touched">
            <span class="text-danger" *ngIf="formNewPassword.controls.password.errors?.required">
              Campo obrigatório
            </span>
          </ng-container>
        </div>

        <div class="txt_field">
          <input id="confirmPassword" type="password" required formControlName="confirmPassword">

          <label for="confirmPassword">Confirmar senha</label>

          <ng-container *ngIf="formNewPassword.controls.confirmPassword.touched">
            <span class="text-danger" *ngIf="formNewPassword.controls.confirmPassword.errors?.required">
              Campo obrigatório
            </span>
            <span class="text-danger" *ngIf="formNewPassword.controls.confirmPassword.errors?.MatchPassword">
              As senhas não são iguais
            </span>
            <span class="text-danger mt-4 mb-4" *ngIf="failChangePassword === true">
              Erro, senha não atualizada
            </span>
          </ng-container>
        </div>

        <button type="submit" class="btn btn-lg" [disabled]="!this.formNewPassword.valid">
          CONFIRMAR
          <span class="spinner-border" style="width: 1.5rem; height: 1.5rem;" role="status" [hidden]="!buttonForm">
          </span>
        </button>
      </form>
    </div>

  </nb-layout-column>
</nb-layout>