export class ReturnErrorLogin {
  isValid: boolean;
  message: string;

  constructor(isValid: boolean = false,
              message: string = 'Usuário e senha inválidos') {
    this.isValid = isValid;
    this.message = message;
  }
}
