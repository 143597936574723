import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

import {NbButtonModule, NbCardModule, NbIconModule, NbLayoutModule} from '@nebular/theme';
import {RecoverComponent} from './recover.component';
import {SharedModule} from '../../shared/shared.module';
import { AlertSuccessNewPasswordComponent } from './alert-success-new-password/alert-success-new-password.component';

@NgModule({
  declarations: [
    RecoverComponent,
    AlertSuccessNewPasswordComponent,
  ],
  imports: [
    CommonModule,
    NbLayoutModule,
    SharedModule,
    ReactiveFormsModule,
    NbCardModule,
    NbIconModule,
    NbButtonModule,
  ],
})
export class RecoverModule { }
