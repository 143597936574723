<nb-card>
  <nb-card-header>
    <h2>
      <nb-icon class="big-icon" icon="edit"></nb-icon>
      Editar Usuário
    </h2>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="form">
      <nb-form-field class="mt-2">
        <nb-icon nbPrefix icon="person" pack="eva"></nb-icon>
        <input nbInput fullWidth type="text" shape="round" formControlName="name"
               [ngClass]="applyCssError('name')">
      </nb-form-field>
      <div class="text-danger mt-1 ml-3"
           *ngIf="form.get('name')?.invalid && form.get('name')?.touched">
        <div *ngIf="form.get('name')?.errors?.['required']">
          <span>Nome é obrigatório</span>
        </div>
      </div>

      <nb-form-field class="mt-2">
        <nb-icon nbPrefix icon="briefcase" pack="eva"></nb-icon>
        <input nbInput fullWidth type="text" shape="round" formControlName="company"
               [ngClass]="applyCssError('company')">
      </nb-form-field>
      <div class="text-danger mt-1 ml-3"
           *ngIf="form.get('company')?.invalid && form.get('company')?.touched">
        <div *ngIf="form.get('company')?.errors?.['required']">
          <span>Empresa é obrigatório</span>
        </div>
      </div>

      <nb-form-field class="mt-2">
        <nb-icon nbPrefix icon="lock" pack="eva"></nb-icon>
        <input nbInput fullWidth type="password" shape="round" formControlName="password"
               placeholder="Senha"
               [ngClass]="applyCssError('password')">
      </nb-form-field>
      <div class="text-danger mt-1 ml-3"
           *ngIf="form.get('password')?.invalid && form.get('password')?.touched">
        <div *ngIf="form.get('password')?.errors?.['required']">
          <span>Senha é obrigatório</span>
        </div>
      </div>

      <nb-form-field class="mt-2">
        <nb-icon nbPrefix icon="lock" pack="eva"></nb-icon>
        <input nbInput fullWidth type="password" shape="round" formControlName="confirmPassword"
               placeholder="Confirmar Senha"
               [ngClass]="applyCssError('confirmPassword')">
      </nb-form-field>
      <div class="text-danger mt-1 ml-3"
           *ngIf="form.get('confirmPassword')?.invalid && form.get('confirmPassword')?.touched">
        <div *ngIf="form.get('confirmPassword')?.errors?.['required']">
          <span>Confirmar Senha é obrigatório</span>
        </div>
        <div *ngIf="form.get('confirmPassword')?.errors?.['MatchPassword']">
          <span>As senhas não são iguais</span>
        </div>
      </div>

      <div class="mt-3">
        <button nbButton type="button" shape="round" size="medium" status="success"
                (click)="add()">ALTERAR</button>
        <button nbButton type="button" class="m-sm-2" shape="round" size="medium" status="danger"
                (click)="cancel()">CANCELAR</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
