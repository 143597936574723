import { Injectable } from '@angular/core';

import {CheckValidationServerService} from './server/check-validation-server.service';

@Injectable({
  providedIn: 'root',
})
export class VerifyValidationService {

  constructor(private checkValidationServerService: CheckValidationServerService) { }

  async validatingEmail(token: string) {
    let returnValidation = false;

    await this.checkValidationServerService.get('/public/users/validation/', token)
      .toPromise().then(() => {
      returnValidation = true;
    }).catch(() => {
      returnValidation = false;
    });
    return returnValidation;
  }
}
