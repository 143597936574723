import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SecurityServerService {

  constructor(private httpClient: HttpClient) { }

  checkToken() {
    return this.httpClient.get(environment.URL_BACKEND_SERVER + '/security');
  }
}
