import { Injectable } from '@angular/core';

import {LoginResponse} from '../models/login/LoginResponse';
import {User} from '../models/user/User';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  constructor() { }

  save(loginResponse: LoginResponse) {
    localStorage.setItem('token', loginResponse.token);
    localStorage.setItem('user', JSON.stringify(loginResponse.user));
  }

  updateUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user')) as User;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  delete() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  existsToken() {
    return 'token' in localStorage;
  }
}
