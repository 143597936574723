import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {NbDialogRef, NbIconConfig, NbToastrService} from '@nebular/theme';
import {StorageService} from '../../services/storage.service';
import {User} from '../../models/user/User';
import {ValidatePassword} from '../../shared/validate-password/validate-password';
import {UserServerService} from '../../services/server/user-server.service';

@Component({
  selector: 'ngx-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {

  form: FormGroup;
  userData: User = this.storageService.getUser();

  constructor(private ref: NbDialogRef<EditUserComponent>,
              private formBuilder: FormBuilder,
              private storageService: StorageService,
              private toastrService: NbToastrService,
              private userServerService: UserServerService) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      company: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
    },
      {
        validator: ValidatePassword.MatchPassword,
    });

    this.form.get('name')?.setValue(this.userData.name);
    this.form.get('company')?.setValue(this.userData.company);
  }

  verifyValidTouched(field: string) {
    return !this.form.get(field)?.valid && this.form.get(field)?.touched;
  }

  applyCssError(field: string) {
    return {
      'caption status-danger': this.verifyValidTouched(field),
    };
  }

  add() {
    if (this.form.valid) {
      const changeUser = {
        name: this.form.get('name').value,
        company: this.form.get('company').value,
        password: this.form.get('password').value,
      };

      const verifyEditUser = this.putInServer(changeUser);

      this.ref.close(verifyEditUser);
      return;
    }

    const iconConfig: NbIconConfig = {icon: 'alert-circle', pack: 'eva'};
    this.toastrService.danger(status, `Por gentileza, verifique os campos "senhas"`, iconConfig);
  }

  cancel() {
    this.ref.close();
  }

  async putInServer(user) {
    const changeUser = {
      id: this.storageService.getUser().id,
      name: user.name,
      email: this.storageService.getUser().email,
      company: user.company,
      password: user.password,
      isConfirmed: this.storageService.getUser().isConfirmed,
      mobileToken: this.storageService.getUser().mobileToken,
    };

    let isEdited: boolean;
    const editUser = this.userServerService.put('/api/users', changeUser).toPromise();

    await editUser.then((data) => {
      this.storageService.updateUser(data);
      isEdited = true;
    }).catch(() => {
      this.putError();
      isEdited = false;
    });
    return isEdited = false;
  }

  putError() {
    const iconConfig: NbIconConfig = {icon: 'alert-circle', pack: 'eva'};
    this.toastrService.danger(status, `Usuário não foi modificado`, iconConfig);
  }
}
