<nb-card>
  <nb-card-header>
    <h2>
      <nb-icon class="big-icon" icon="lock"></nb-icon>
      REDEFINIR SENHA
    </h2>
  </nb-card-header>
  <nb-card-body>
    <p>Legal, sua senha foi redefinida com sucesso, realize o login novamente!</p>

    <div class="mt-4">
      <button nbButton type="button" shape="round" size="medium" status="success"
              (click)="returnLogin()">IR PARA O LOGIN</button>
    </div>
  </nb-card-body>
</nb-card>
