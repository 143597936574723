import {Injectable} from '@angular/core';

import {RecoverServerService} from './server/recover-server.service';
import {ReturnRecover} from '../models/ReturnRecover';
import {User} from '../models/user/User';

@Injectable({
  providedIn: 'root',
})
export class VerifyRecoverService {

  constructor(private recoverServerService: RecoverServerService) {
  }

  async verifyRecoverPassword(token: string) {
    const returnRecover = new ReturnRecover();

    await this.recoverServerService.get('/public/users/reset-password/', token)
      .toPromise()
      .then((data) => {
        if (data) {
          returnRecover.validToken = true;
        }
        return returnRecover.user = data;
      }).catch(() => {
        return returnRecover;
      });
    return returnRecover;
  }

  async changePassword(user: User) {
    let updatePassword = false;

    await this.recoverServerService.put('/public/users', user)
      .toPromise()
      .then(() => {
        updatePassword = true;
      }).catch(() => {
        return updatePassword;
      });
    return updatePassword;
  }
}
