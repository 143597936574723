<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" routerLink="/pages/rooms-conference">
      <img src="assets/images/logo/new-logovip.png" alt="Logo da VIP"/>
      Vip Solutions
    </a>
  </div>
</div>

<nb-actions size="small">
  <!--  <nb-action [nbContextMenu]="editProfile"-->
  <!--             nbContextMenuTag="edit-profile">-->
  <!--  </nb-action>-->
  <nb-action class="edit-user" (click)="openWithoutBackdropClick()">
    <nb-icon icon="person" class="mb-2"></nb-icon>
    <h6 class="ml-1"> {{ username | titlecase }} </h6>
    <nb-icon icon="arrow-down" class="mb-1"></nb-icon>
  </nb-action>
  <nb-action icon="power-outline" (click)="logout()"></nb-action>
</nb-actions>
