import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../environments/environment';
import {ReturnResetPassword} from '../../models/ReturnResetPassword';
import {User} from '../../models/user/User';

@Injectable({
  providedIn: 'root',
})
export class RecoverServerService {

  private URL_SERVER: string = environment.URL_BACKEND_SERVER;

  constructor(private httpClient: HttpClient) { }

  get(resource: string,
      token: string) {
    return this.httpClient.get<ReturnResetPassword>(`${this.URL_SERVER}${resource}${token}`);
  }

  put(resource: string,
      user: User) {
    return this.httpClient.put<User>(this.URL_SERVER + resource, user);
  }
}
