import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {NbDialogService} from '@nebular/theme';
import {ValidatePassword} from '../../shared/validate-password/validate-password';
import {VerifyRecoverService} from '../../services/verify-recover.service';
import {User} from '../../models/user/User';
import {ReturnResetPassword} from '../../models/ReturnResetPassword';
import {AlertSuccessNewPasswordComponent} from './alert-success-new-password/alert-success-new-password.component';

@Component({
  selector: 'ngx-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent implements OnInit {

  title: string = 'REDEFINIR SENHA';
  token: string;
  formNewPassword: FormGroup;
  returnUser: ReturnResetPassword;
  failChangePassword = false;

  showSpinner = false;
  showForm = false;
  buttonForm = false;
  errorToken = false;

  constructor(private activatedRoute: ActivatedRoute,
              private verifyRecoverService: VerifyRecoverService,
              private fb: FormBuilder,
              private dialogService: NbDialogService) { }

  ngOnInit(): void {
    this.formPasswords();
    this.token = this.activatedRoute.snapshot.params['token'];

    this.validatingToken();
  }

  async validatingToken() {
    this.showSpinner = true;

    const validation = await this.verifyRecoverService.verifyRecoverPassword(this.token);

    if (validation.validToken === true) {
      this.showSpinner = false;
      this.showForm = true;
      this.returnUser = {
        id: validation.user.id,
        name: validation.user.name,
        email: validation.user.email,
        company: validation.user.company,
        isConfirmed: validation.user.isConfirmed,
        mobileToken: validation.user.mobileToken,
      };
      return;
    }
    this.showSpinner = false;
    this.errorToken = true;
  }

  formPasswords() {
    this.formNewPassword = this.fb.group({
        password: [null, Validators.required],
        confirmPassword: [null, Validators.required],
      },
      {
        validator: ValidatePassword.MatchPassword,
      });
  }

  async confirm() {
    this.buttonForm = true;
    this.failChangePassword = false;

    const user: User = {
      id: this.returnUser.id,
      name: this.returnUser.name,
      email: this.returnUser.email,
      company: this.returnUser.company,
      password: this.formNewPassword.get('confirmPassword').value,
      isConfirmed: this.returnUser.isConfirmed,
      mobileToken: this.returnUser.mobileToken,
    };

    const passwordUpdate = await this.verifyRecoverService.changePassword(user);
    if (passwordUpdate === true) {
      this.buttonForm = false;
      this.dialogService.open(AlertSuccessNewPasswordComponent);
      return;
    }
    this.failChangePassword = true;
    this.buttonForm = false;
  }
}
