<div id="carouselImages" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="overlay-color"></div>
    <div class="carousel-item active">
      <!-- <img class="carousel-size-imgs" src="https://vip-apps.github.io/vip-meets-images/propaganda-1.png" -->
      <img class="carousel-size-imgs first-image" src="../../../assets/carrousel/propaganda1.svg" alt="First slide"
        (error)="loadImageError($event)" width="900">
    </div>
    <div class="carousel-item button">
      <!--  <img class="carousel-size-imgs" src="https://vip-apps.github.io/vip-meets-images/propaganda-2.png" -->
      <img class="carousel-size-imgs mb-5" src="../../../assets/carrousel/propaganda2.png" loading="" alt="Second slide"
        (error)="loadImageError($event)">
      <div class="carousel-caption d-none d-md-block">
        <a href="https://vipsolutions.com.br" target="_blank">
          <button class="mb-3">Conhecer</button></a>
      </div>

    </div>
    <div class="carousel-item ">
      <img class="carousel-size-imgs third-image " src="../../../assets/carrousel/propaganda3.svg" alt="Third slide"
        (error)="loadImageError($event)">
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselImages" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselImages" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>