import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  NbButtonModule,
  NbCardModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
} from '@nebular/theme';
import {EditUserComponent} from './edit-user.component';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    EditUserComponent,
  ],
    imports: [
        CommonModule,
        NbIconModule,
        NbCardModule,
        NbFormFieldModule,
        NbInputModule,
        NbButtonModule,
        ReactiveFormsModule,
    ],
})
export class EditUserModule { }
