import { Component } from '@angular/core';
import {Router} from '@angular/router';

import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'ngx-alert-success-new-password',
  templateUrl: './alert-success-new-password.component.html',
  styleUrls: ['./alert-success-new-password.component.scss'],
})
export class AlertSuccessNewPasswordComponent {

  constructor(private router: Router,
              private ref: NbDialogRef<AlertSuccessNewPasswordComponent>) { }

  returnLogin() {
    this.router.navigate(['/login-register/login']);
    this.ref.close();
  }
}
